import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/dataTables.buttons";
import DataTable from "datatables.net-dt";
import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CiSearch } from "react-icons/ci";
import { Audio } from "react-loader-spinner";
import Select from "react-select";
import { MyContext } from "../../context";
import { formatDate } from "../../utils/dateFormatter";
import "./Tables.css";

function Table(prop) {
  const [counter, setCounter] = useState(null);
  const [endLimitDate, setEndLimitDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useContext(MyContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const [project, setProject] = useState([]);
  const [tableStartDate, setTableStartDate] =useState(null);
  const [tableEndDate, setTableEndDate] =useState(null);

  const setTimeperiod = () => {
    const dateRange = [startDate, endDate];
    let formattedDate = [];
    for (let i = 0; i < dateRange.length; i++) {
      const formatted = formatDate(new Date(dateRange[i]),0);
      formattedDate.push(formatted);
    }
    setTableStartDate(formattedDate[0]);
    setTableEndDate(formattedDate[1]);
    getRecords(formattedDate[0], formattedDate[1]);
  };

  useEffect(() => {
    let table;
    if (endDate === "") {
      // Format the date
      const formatted = formatDate(new Date(),1);
      setEndLimitDate(formatted);
      setEndDate(formatted);   
      setStartDate(formatted);
      setTableStartDate(formatted);
      setTableEndDate(formatted);
      //fetch projects
      fetch(process.env.REACT_APP_API_URL + `project/all`, {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (response.ok === false) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((res) => {
          let options = [];
          if (res.data) {
            res.data.forEach((x) => {
              let obj = { value: x.name, label: x.name };
              options.push(obj);
            });
          }
          setProject(options);
        })
        .catch((error) => {
          console.error(
            "There was a problem with your fetch operation:",
            error
          );
        });
    }
    if (endDate && records.length === 0) {
      getRecords(startDate, endDate);
    }
    if (records.length) {
      table = new DataTable("#myTable", {
        dom: '<"flex-container"<"length-menu"l><"buttons-search"Bf>>rt<"bottom"ip>',
        responsive: true,
        scrollX: true,
        scrollY: 400,
        lengthMenu: [
          [10, 100, 300, 500, -1],
          [10, 100, 300, 500, "All"],
        ],
        buttons: ["excel", "csv"], // buttons (Excel and Csv)
      });
    }
    if (counter === 1) {
      setCounter(0);
      setTimeperiod();
    }
    if (records.length) {
      return () => {
        // Destroy the DataTable instance when the component unmounts
        table.destroy();
      };
    }
  }, [endDate, records, counter]);

  const getRecords = (startDate, endDate) => {
    fetch(
      process.env.REACT_APP_API_URL +
        `report?from_date=${startDate}&to_date=${endDate}`,
      {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        if (response.ok === false) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((res) => {
        const data = res.data;
        setRecords(data);
        getLeaves(startDate, endDate, data);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  const getLeaves = (startDate, endDate, data) => {
    fetch(
      process.env.REACT_APP_API_URL +
        `report/leave?from_date=${startDate}&to_date=${endDate}`,
      {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        if (response.ok === false) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((res) => {
        const RECORD = [];
        data.forEach((element) => {
          const match = res.data.find(
            (item) => element.userInfo.email === item.user_email.toLowerCase()
          );
          let obj = {};
          if (!match) {
            obj = {
              ...element,
              total_leaves: "-",
              working_days: "-",
              total_working_hours: "-",
              slTotalTime: "-",
              slCount: "-",
              oooTotalTime:'-'
            };
          } else {
            obj = {
              ...element,
              total_leaves: match.total_leaves,
              working_days: match.working_days,
              total_working_hours: match.total_working_hours,
              slTotalTime: match.slTotalTime,
              slCount: match.slCount,
              oooTotalTime:match.oooTotalTime,
            };
          }
          RECORD.push(obj);
        });
        setRecords(RECORD);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  return (
    <div>
      {/* loader */}
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
            zIndex="1000"
          />
        </div>
      )}

      {/*Main application content */}
      {!isLoading && (
        <div className="w-full">
          {/* date piker and office selection*/}
          <div className="justify-between mb-1 selection">
            <div className="flex flex-row justify-center ">
              <div className="flex">
                <input
                  id="startDate"
                  type="date"
                  min="2012-01-01"
                  max={endLimitDate}
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  className="bg-gray-50 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 px-2.5 py-0.5 mr-2"
                  placeholder="Select start date"
                />
                <input
                  id="startDate"
                  type="date"
                  min="2012-01-01"
                  max={endLimitDate}
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  className="bg-gray-50 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 px-2.5 py-0.5 mr-2"
                  placeholder="Select start date"
                />
                <div
                  className="bg-white flex flex-col items-center justify-center px-2 rounded"
                  onClick={() => {
                    setCounter(1);
                    setIsLoading(true);
                  }}
                >
                  <CiSearch />
                </div>
              </div>
            </div>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={project}
            />
            <div className="officeBttn flex flex-row justify-center">
              <button
                type="button"
                className="text-dark bg-slate-50 hover:bg-sky-900 hover:text-white focus:bg-sky-900 focus:text-white font-medium rounded text-sm px-5 py-1 "
              >
                DDN
              </button>
              <button
                type="button"
                className="text-dark bg-slate-50 hover:bg-sky-900 hover:text-white focus:bg-sky-900 focus:text-white font-medium rounded text-sm px-5 py-1 mx-2"
              >
                HDR
              </button>
              <button
                type="button"
                className="text-dark bg-slate-50 hover:bg-sky-900 hover:text-white focus:bg-sky-900 focus:text-white font-medium rounded text-sm px-5 py-1 "
              >
                ALL
              </button>
            </div>
          </div>
          {/* datatable */}
          <table
            className=" text-sm text-left rtl:text-right text-gray-500 display"
            id="myTable"
          >
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {prop.type}_No.
                </th>
                <th scope="col" className="px-6 py-3">
                  Emp_ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Emp_Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Office
                </th>
                <th scope="col" className="px-6 py-3">
                  Project Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Project
                </th>
                <th scope="col" className="px-6 py-3">
                  Competency
                </th>
                <th scope="col" className="px-6 py-3">
                  Designation
                </th>
                <th scope="col" className="px-6 py-3">
                  Domain
                </th>
                <th scope="col" className="px-6 py-3">
                  Billed Hours
                </th>
                <th scope="col" className="px-6 py-3">
                  Logged Hours
                </th>
                <th scope="col" className="px-6 py-3">
                  Billable Goal
                </th>
                <th scope="col" className="px-6 py-3">
                  Billable %
                </th>
                <th scope="col" className="px-6 py-3">
                  Internal Project(Hrs)
                </th>
                <th scope="col" className="px-6 py-3">
                  WFO/WFH hours
                </th>
                <th scope="col" className="px-6 py-3">
                  Days Worked
                </th>
                <th scope="col" className="px-6 py-3">
                  Working Hrs for the {prop.type}
                </th>
                <th scope="col" className="px-6 py-3">
                  No. of SLs
                </th>
                <th scope="col" className="px-6 py-3">
                  Total SL Hrs
                </th>
                <th scope="col" className="px-6 py-3">
                  Total time for OOO
                </th>
                <th scope="col" className="px-6 py-3">
                  Leaves
                </th>
              </tr>
            </thead>
            <tbody>
              {records.map((row, index) => {
                let projects = [];
                let projectsId = [];
                row.projects?.forEach((x) => {
                  projects.push(x.name);
                  projectsId.push(x.project_club_id);
                });
                return (
                  <>
                    <tr className="bg-white border-b" key={index}>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900"
                      >
                        {tableStartDate + "\n" + tableEndDate}
                      </th>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900"
                      >
                        {row.userInfo.userDetails?.employee_id || "-"}
                      </th>
                      <td className="px-6 py-4">
                        {row.userInfo.first_name +
                          " " +
                          (row.userInfo.last_name || "") || "-"}
                      </td>
                      <td className="px-6 py-4">
                        {row.userInfo.userDetails?.office || "-"}
                      </td>
                      <td className="px-6 py-4">
                        {JSON.stringify(projectsId) || "-"}
                      </td>
                      <td className="px-6 py-4">
                        {JSON.stringify(projects) || "-"}
                      </td>
                      <td className="px-6 py-4">
                        {row.userInfo.userDetails?.competency || "-"}
                      </td>
                      <td className="px-6 py-4">
                        {row.userInfo.userDetails?.designation || "-"}
                      </td>
                      <td className="px-6 py-4">
                        {row.userInfo.userDetails?.domain || "-"}
                      </td>
                      <td className="px-6 py-4">{row.billable_hours}</td>
                      <td className="px-6 py-4">{row.logged_hours}</td>
                      <td className="px-6 py-4">
                        {row.userInfo.userDetails?.goal || "-"}
                      </td>
                      <td className="px-6 py-4">
                        {Math.round(
                          (row.billable_hours / (row.working_days * 8)) * 100
                        ) === 0
                          ? 0
                          : Math.round(
                              (row.billable_hours / (row.working_days * 8)) *
                                100
                            ) || "-"}
                      </td>
                      <td className="px-6 py-4">
                        {row.internal_project_hours || "0"}
                      </td>
                      <td className="px-6 py-4">
                        {row.total_work_from_Office_hours || "0"}/
                        {row.total_work_from_Home_hours || "0"}
                      </td>
                      <td className="px-6 py-4">
                        {row.working_days === 0 ? "0" : row.working_days ?? "-"}
                      </td>
                      <td className="px-6 py-4">
                        {row.total_working_hours === 0
                          ? "0"
                          : row.total_working_hours ?? "-"}
                      </td>
                      <td className="px-6 py-4">
                        {row.slCount === 0 ? "0" : row.slCount ?? "-"}
                      </td>
                      <td className="px-6 py-4">
                        {row.slTotalTime === 0 ? "0" : row.slTotalTime ?? "-"}
                      </td>
                      <td className="px-6 py-4">
                        {row.oooTotalTime === 0 ? "0" : row.oooTotalTime ?? "-"}
                      </td>
                      <td className="px-6 py-4">
                        {row.total_leaves === 0 ? "0" : row.total_leaves ?? "-"}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Table;
